import * as React from "react"
import './Fortuna.css';
import Creative from "../../components/Creative"
import backgroundFortuna from "./images/fortuna_background.jpg"
import heroMamed from "./images/fortuna_mamed.png"
import heroSoldic from "./images/fortuna_soldic.png"
import heroTorres from "./images/fortuna_torres.png"
import heroParnasse from "./images/fortuna_parnasse.png"
import fortunaFog from "./images/fortuna_fog.png"
import video from "./images/fortuna_video.mp4"
import SimpleSidebar from "../../components/layout"

const Fortuna = () => (
  <SimpleSidebar>
    <Creative
      className="fortuna"
      video={video}
      background={backgroundFortuna}
      heading="Fortuna KSW 65"
      tags={["api", "interaction", "animation"]}
      tagBackground="#ffdb01"
      tagColor="#000000"
      headingColor="#ffffff"
    >
      <img src={heroParnasse} className="fortuna_image parnasse"/>
      <img src={heroTorres} className="fortuna_image torres"/>
      <img src={heroSoldic} className="fortuna_image soldic"/>
      <img src={heroMamed} className="fortuna_image mamed"/>
    </Creative>
    <img src={fortunaFog} className="fortuna_fog"/>
  </SimpleSidebar>
)

export default Fortuna
